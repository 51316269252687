<!--
 * @Author       : Hugo
 * @Date         : 2020-09-18 11:04:06
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-11-08 16:16:11
 * @Description  : 
 * @FilePath     : /miaohang/src/views/test/Wrapper.vue
-->
<template>
  <div class="test_page">
    <br>
    <router-link :to="{name: 'test_delcontact'}" class="router_link">删除联系人</router-link>
    <br>
    <div class="button"
      @click="handleElectron"
    >Electron Click</div>
    <br><br>
    <router-link :to="{name: 'test_simple_upload'}">大文件上传</router-link>
    <br><br>
    <div class="components">
      <h3>组件</h3>
      <select-area></select-area>
    </div>
    <div class="scrollbar">
      <el-scrollbar class="box">
          <div class="child"></div>
      </el-scrollbar>
    </div>
    <div
      class="contextmenu"
      @contextmenu.prevent="contextmenu"
      style="line-height:40px;"
    >右键点击</div>
    <br>
    <div>
      <router-link :to="{name: 'test_draggable_next'}">关联拖拽</router-link>
    </div>
  </div>
</template>
<script>
import SelectArea from '@/components/Module/SelectArea.vue';
export default {
  components:{
    SelectArea,
  },
  methods: {
    contextmenu(){
      // console.log('右键点击了');
    },
    handleElectron(){
      window.open('https://www.baidu.com', '_blank', 'top=500,left=200,frame=false,nodeIntegration=no')
    }
  }
}
</script>
<style lang="scss" scoped>
.test_page{
  @include bbox;
  padding: 40px;
  .router_link{
    font-size: 18px;
    cursor: pointer;
  }
}
.scrollbar{
  .box{
    width: 200px;
    height: 100px;
    border: 1px solid #ccc;
    .child{
      width: 400px;
      height: 500px;
    }
  }
}
</style>