var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "test_page" },
    [
      _c("br"),
      _c(
        "router-link",
        {
          staticClass: "router_link",
          attrs: { to: { name: "test_delcontact" } },
        },
        [_vm._v("删除联系人")]
      ),
      _c("br"),
      _c("div", { staticClass: "button", on: { click: _vm.handleElectron } }, [
        _vm._v("Electron Click"),
      ]),
      _c("br"),
      _c("br"),
      _c("router-link", { attrs: { to: { name: "test_simple_upload" } } }, [
        _vm._v("大文件上传"),
      ]),
      _c("br"),
      _c("br"),
      _c(
        "div",
        { staticClass: "components" },
        [_c("h3", [_vm._v("组件")]), _c("select-area")],
        1
      ),
      _c(
        "div",
        { staticClass: "scrollbar" },
        [
          _c("el-scrollbar", { staticClass: "box" }, [
            _c("div", { staticClass: "child" }),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "contextmenu",
          staticStyle: { "line-height": "40px" },
          on: {
            contextmenu: function ($event) {
              $event.preventDefault()
              return _vm.contextmenu.apply(null, arguments)
            },
          },
        },
        [_vm._v("右键点击")]
      ),
      _c("br"),
      _c(
        "div",
        [
          _c(
            "router-link",
            { attrs: { to: { name: "test_draggable_next" } } },
            [_vm._v("关联拖拽")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }